import React from "react"

export default function Javascript() {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70 0H0V70H70V0ZM23.7622 55.2553L18.4055 58.4972L18.4053 58.4966C19.9559 61.7858 23.0105 64.5112 28.2728 64.5112C34.0998 64.5112 38.0936 61.4098 38.0936 54.5966V32.137H31.5155V54.5036C31.5155 57.7925 30.1524 58.638 27.9909 58.638C25.7359 58.638 24.7958 57.0876 23.7622 55.2553ZM47.0227 54.6912L41.6666 57.7923L41.6663 57.7925C43.5927 61.5988 47.5398 64.512 53.6481 64.512C59.8975 64.512 64.5492 61.2701 64.5492 55.3491C64.5492 49.8519 61.4011 47.4088 55.8093 45.0121L54.1649 44.3074C51.3457 43.0857 50.124 42.2864 50.124 40.313C50.124 38.7156 51.3457 37.4939 53.2724 37.4939C55.152 37.4939 56.3732 38.2926 57.5011 40.313L62.6231 37.0236C60.4619 33.2176 57.4546 31.7607 53.2727 31.7607C47.3989 31.7607 43.64 35.52 43.64 40.4539C43.64 45.8105 46.7881 48.348 51.5341 50.3679L53.1783 51.0734C56.1856 52.3886 57.9711 53.1876 57.9711 55.4429C57.9711 57.3225 56.2332 58.6853 53.5073 58.6853C50.2654 58.6853 48.4328 56.9938 47.0227 54.6912Z"
        fill="white"
      />
    </svg>
  )
}
