import React from "react"

export default function MQTT() {
  return (
    <svg
      width="149"
      height="70"
      viewBox="0 0 149 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.4785 49.6518V17.6953H49.3231L55.2343 39.4937L61.0789 17.6953H70.9457V49.6518H64.8345V24.4964L58.3677 49.6518H52.0343L45.5897 24.4964V49.6518H39.4785Z"
        fill="white"
      />
      <path
        d="M97.6578 45.7428C99.2714 46.8764 101.026 47.7774 102.921 48.4459L100.5 53.0017C99.5083 52.7111 98.5386 52.3114 97.5912 51.8028C97.3839 51.7011 95.9258 50.7565 93.2167 48.969C91.0849 49.8845 88.7236 50.3423 86.133 50.3423C81.1293 50.3423 77.2062 48.8964 74.3639 46.0044C71.5363 43.1125 70.1226 39.0507 70.1226 33.8191C70.1226 28.602 71.5437 24.5475 74.3861 21.6556C77.2284 18.7492 81.0848 17.2959 85.9553 17.2959C90.7814 17.2959 94.6082 18.7492 97.4358 21.6556C100.263 24.5475 101.677 28.602 101.677 33.8191C101.677 36.5803 101.285 39.0071 100.5 41.0998C99.908 42.6983 98.9605 44.246 97.6578 45.7428ZM92.3728 42.1025C93.2166 41.1289 93.8458 39.9517 94.2603 38.5712C94.6896 37.1906 94.9043 35.6066 94.9043 33.8191C94.9043 30.1279 94.0753 27.3741 92.4172 25.5575C90.7592 23.7265 88.5904 22.8109 85.9109 22.8109C83.2314 22.8109 81.0552 23.7265 79.3824 25.5575C77.7244 27.3886 76.8953 30.1425 76.8953 33.8191C76.8953 37.5539 77.7244 40.3514 79.3824 42.2115C81.0552 44.0571 83.1648 44.9799 85.7111 44.9799C86.6585 44.9799 87.5541 44.8273 88.398 44.5221C87.0656 43.6647 85.7111 42.9963 84.3343 42.5167L86.1774 38.8327C88.3388 39.5594 90.4039 40.6493 92.3728 42.1025Z"
        fill="white"
      />
      <path
        d="M107.014 49.6518V23.1013H97.3091V17.6953H123.3V23.1013H113.617V49.6518H107.014Z"
        fill="white"
      />
      <path
        d="M132.287 49.6518V23.1013H122.582V17.6953H148.573V23.1013H138.89V49.6518H132.287Z"
        fill="white"
      />
      <path
        d="M141.461 24.6914C141.592 24.6914 141.703 24.7082 141.793 24.7419C141.887 24.7756 141.961 24.821 142.017 24.8782C142.075 24.9354 142.117 25.0028 142.141 25.0802C142.169 25.1576 142.183 25.2418 142.183 25.3326C142.183 25.4202 142.169 25.5026 142.141 25.5801C142.117 25.6608 142.075 25.7298 142.017 25.7871C141.961 25.8443 141.887 25.8897 141.793 25.9234C141.703 25.9571 141.592 25.9739 141.461 25.9739C141.333 25.9739 141.222 25.9571 141.128 25.9234C141.038 25.8897 140.964 25.8443 140.905 25.7871C140.846 25.7298 140.803 25.6608 140.775 25.5801C140.747 25.5026 140.734 25.4202 140.734 25.3326C140.734 25.2418 140.747 25.1576 140.775 25.0802C140.803 25.0028 140.846 24.9354 140.905 24.8782C140.964 24.821 141.038 24.7756 141.128 24.7419C141.222 24.7082 141.333 24.6914 141.461 24.6914Z"
        fill="white"
      />
      <path
        d="M144.692 33.4466C144.106 33.4466 143.573 33.3776 143.092 33.2396C142.61 33.105 142.198 32.903 141.856 32.6337C141.513 32.3644 141.248 32.0295 141.061 31.6289C140.874 31.2317 140.78 30.7723 140.78 30.2505C140.78 29.7018 140.874 29.2255 141.061 28.8216C141.248 28.421 141.513 28.0878 141.856 27.8219C142.198 27.5593 142.61 27.3641 143.092 27.2362C143.577 27.1083 144.113 27.0443 144.702 27.0443C145.291 27.0443 145.824 27.1083 146.302 27.2362C146.783 27.3641 147.193 27.561 147.533 27.8269C147.872 28.0928 148.134 28.4278 148.317 28.8317C148.504 29.2356 148.598 29.7119 148.598 30.2606C148.598 30.7824 148.504 31.2401 148.317 31.634C148.134 32.0312 147.87 32.3644 147.528 32.6337C147.188 32.903 146.778 33.105 146.297 33.2396C145.819 33.3776 145.284 33.4466 144.692 33.4466ZM144.692 28.039C144.217 28.039 143.79 28.0827 143.409 28.1703C143.031 28.2578 142.709 28.3907 142.442 28.5691C142.179 28.7509 141.977 28.9798 141.835 29.2558C141.696 29.5352 141.627 29.8668 141.627 30.2505C141.627 30.6342 141.696 30.9641 141.835 31.2401C141.977 31.5195 142.179 31.7484 142.442 31.9268C142.709 32.1086 143.031 32.2416 143.409 32.3257C143.79 32.4099 144.217 32.4519 144.692 32.4519C145.169 32.4519 145.597 32.4099 145.975 32.3257C146.352 32.2416 146.671 32.1103 146.93 31.9319C147.193 31.7535 147.394 31.5263 147.533 31.2502C147.675 30.9742 147.746 30.6443 147.746 30.2606C147.746 29.8769 147.675 29.5453 147.533 29.2659C147.394 28.9865 147.193 28.756 146.93 28.5742C146.671 28.3924 146.352 28.2578 145.975 28.1703C145.597 28.0827 145.169 28.039 144.692 28.039Z"
        fill="white"
      />
      <path
        d="M144.022 36.0318H140.884V35.0926H148.478V36.8901C148.478 37.7855 148.3 38.4469 147.943 38.8744C147.587 39.3019 147.046 39.5157 146.323 39.5157C146.021 39.5157 145.758 39.4736 145.533 39.3895C145.308 39.3087 145.114 39.1993 144.951 39.0613C144.792 38.9266 144.659 38.7718 144.551 38.5967C144.444 38.4217 144.356 38.2416 144.286 38.0565L140.884 40.066V38.9603L144.022 37.188V36.0318ZM144.842 36.0318V36.8598C144.842 37.1628 144.872 37.4203 144.931 37.6324C144.993 37.8444 145.083 38.0178 145.201 38.1524C145.322 38.2904 145.471 38.3897 145.647 38.4503C145.827 38.5143 146.037 38.5462 146.276 38.5462C146.525 38.5462 146.736 38.5109 146.91 38.4402C147.083 38.3729 147.225 38.2685 147.335 38.1272C147.446 37.9892 147.526 37.8124 147.574 37.597C147.626 37.3849 147.652 37.1325 147.652 36.8396V36.0318H144.842Z"
        fill="white"
      />
      <path
        d="M144.894 44.1356V46.5794H141.17C141.108 46.3943 141.052 46.2075 141.004 46.019C140.955 45.8305 140.915 45.6369 140.884 45.4383C140.85 45.2397 140.824 45.031 140.806 44.8122C140.789 44.5934 140.78 44.3578 140.78 44.1053C140.78 43.5769 140.87 43.109 141.05 42.7017C141.234 42.2944 141.494 41.951 141.83 41.6717C142.169 41.3923 142.579 41.1802 143.061 41.0355C143.545 40.8907 144.089 40.8184 144.692 40.8184C145.287 40.8184 145.826 40.8975 146.307 41.0557C146.788 41.2172 147.197 41.4512 147.533 41.7575C147.872 42.0638 148.132 42.4408 148.312 42.8885C148.496 43.3396 148.587 43.8529 148.587 44.4285C148.587 44.8021 148.549 45.1606 148.473 45.504C148.397 45.8473 148.286 46.1671 148.141 46.4633L147.299 46.0998C147.358 45.9786 147.413 45.8473 147.465 45.7059C147.517 45.5679 147.562 45.4249 147.6 45.2767C147.642 45.1286 147.675 44.9755 147.699 44.8173C147.723 44.6624 147.735 44.5076 147.735 44.3528C147.735 43.9421 147.664 43.5786 147.522 43.2621C147.384 42.9457 147.183 42.6798 146.92 42.4644C146.657 42.2523 146.336 42.0907 145.959 41.9797C145.582 41.8686 145.156 41.813 144.681 41.813C144.231 41.813 143.819 41.8585 143.445 41.9494C143.071 42.0436 142.749 42.1917 142.479 42.3937C142.209 42.5957 141.999 42.8565 141.85 43.1763C141.701 43.4961 141.627 43.8815 141.627 44.3326C141.627 44.4807 141.63 44.6153 141.637 44.7365C141.648 44.861 141.66 44.9755 141.674 45.0798C141.688 45.1842 141.703 45.2818 141.72 45.3727C141.741 45.4669 141.76 45.5578 141.778 45.6453H144.042V44.1356H144.894Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0322 18H10.3504C15.8897 19.7743 20.989 22.992 25.1576 27.4363C30.1574 32.767 33.5102 39.5337 34.8635 46.8324C34.9526 46.4928 35 46.1368 35 45.7698V42.1795C33.2321 36.3206 30.1773 30.9352 26.0242 26.5074C22.5603 22.8143 18.4753 19.9398 14.0322 18ZM26.9121 18C28.2837 19.0901 29.5966 20.268 30.8423 21.5301C32.3517 23.0593 33.7397 24.6891 35 26.404V24.3195C33.9817 23.0348 32.8909 21.8012 31.7304 20.6255C30.8234 19.7065 29.8817 18.8309 28.9084 18H26.9121ZM26.8196 50C26.3112 43.2534 23.5445 36.89 18.9794 32.0771C14.4134 27.2633 8.38489 24.3554 2 23.8368V25.1489C8.05679 25.6647 13.7716 28.4342 18.1055 33.0033C22.4378 37.5707 25.0725 43.6015 25.5781 50H26.8196ZM19.1094 50C18.5365 45.5304 16.5767 41.3525 13.4951 38.133C10.377 34.8753 6.32528 32.8234 2 32.2631V45.7698C2 48.106 3.92051 50 6.2896 50H19.1094Z"
        fill="white"
      />
    </svg>
  )
}
