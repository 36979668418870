import React from "react"

export default function XMPP() {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M53.1318 10.3457C53.1587 10.8052 52.5063 10.6845 52.5063 11.1482C52.5063 24.6411 36.211 45.2263 20.4155 49.2037V49.7774C41.3991 47.8454 65.4115 26.0474 65.94 5.37988L53.1304 10.346L53.1318 10.3457Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M46.7855 12.0058C46.8121 12.4653 46.8275 12.9263 46.8275 13.3907C46.8275 26.8836 36.0829 45.0647 20.2881 49.0417V49.6154C40.9535 48.6389 57.3216 27.557 57.3216 11.3954C57.3216 10.5641 57.2768 9.74023 57.1917 8.92578L46.7862 12.0051L46.7855 12.0058Z"
        fill="#DDDDDD"
      />
      <path
        d="M57.2916 8.73285L54.6256 9.68555C54.64 10.0222 54.6487 10.4744 54.6487 10.8143C54.6487 25.2409 41.6039 45.1857 24.1035 48.3063C22.9678 48.6871 21.4652 49.0332 20.2773 49.3321V49.9054C43.1162 47.9594 59.1623 24.7523 57.2933 8.73145L57.2916 8.73285Z"
        fill="white"
      />
      <path
        d="M17.5036 10.3447C17.4766 10.8043 18.129 10.6835 18.129 11.1473C18.129 24.6401 34.4243 45.2254 50.2198 49.2028V49.7764C29.2363 47.8444 5.22381 26.0464 4.69531 5.37891L17.505 10.3451L17.5036 10.3447Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M23.85 12.0048C23.8234 12.4644 23.808 12.9253 23.808 13.3898C23.808 26.8826 34.5527 45.0637 50.3475 49.0408V49.6144C29.6821 48.6379 13.314 27.556 13.314 11.3944C13.314 10.5632 13.3588 9.73925 13.4438 8.9248L23.8493 12.0041L23.85 12.0048Z"
        fill="white"
      />
      <path
        d="M13.3439 8.73285L16.0099 9.68555C15.9955 10.0222 15.9868 10.4744 15.9868 10.8143C15.9868 25.2409 29.0316 45.1857 46.532 48.3063C47.6677 48.6871 49.1703 49.0332 50.3582 49.3321V49.9054C27.5193 47.9594 11.4732 24.7523 13.3422 8.73145L13.3439 8.73285Z"
        fill="#DDDDDD"
      />
      <path
        d="M9.09609 60.2271L4.41029 55.0996H8.48534L11.6798 58.6934L14.8749 55.0996H18.95L14.2645 60.2257L19.3049 65.3805H15.0734L11.6794 61.646L8.28689 65.3805H4.05469L9.09609 60.225V60.2271Z"
        fill="white"
      />
      <path
        d="M20.4779 55.0996H25.3205L28.8845 61.7164L32.4486 55.0996H37.2898V65.3805H34.2231V57.9969H34.1951L30.0626 65.3805H27.7061L23.5736 57.9969H23.5449V65.3805H20.4775V55.101L20.4779 55.0996Z"
        fill="white"
      />
      <path
        d="M39.4316 55.0998H48.0227C51.0184 55.0998 51.7425 56.6055 51.7425 58.6218V59.5024C51.7425 61.0358 51.0747 62.7959 48.8463 62.7959H42.5834V65.3807H39.4316V55.0977V55.0998ZM42.5816 60.2413H47.4806C48.2187 60.2413 48.504 59.7593 48.504 59.1482V58.751C48.504 58.0545 48.1624 57.6565 47.2111 57.6565H42.5816V60.2395L42.5816 60.2413Z"
        fill="white"
      />
      <path
        d="M53.4526 55.0998H62.0437C65.0401 55.0998 65.7642 56.6055 65.7642 58.6218V59.5024C65.7642 61.0358 65.0968 62.7959 62.868 62.7959H56.6054V65.3807H53.4526V55.0977V55.0998ZM56.6061 60.2413H61.5047C62.2432 60.2413 62.5274 59.7593 62.5274 59.1482V58.751C62.5274 58.0545 62.1865 57.6565 61.2349 57.6565H56.6061V60.2395V60.2413Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="43.1914"
          y1="49.777"
          x2="43.1914"
          y2="5.37953"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.011" stopColor="#EEEEEE" />
          <stop offset="0.467" stopColor="#939393" />
          <stop offset="0.9945" stopColor="#EEEEEE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="27.4439"
          y1="49.7761"
          x2="27.4439"
          y2="5.37856"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.011" stopColor="#EEEEEE" />
          <stop offset="0.467" stopColor="#939393" />
          <stop offset="0.9945" stopColor="#EEEEEE" />
        </linearGradient>
      </defs>
    </svg>
  )
}
