import React from "react"

export default function Bluetooth() {
  return (
    <svg
      width="176"
      height="70"
      viewBox="0 0 176 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.1709 49.2798V19.5098H47.4509C49.6509 19.5098 51.8109 19.6698 53.2809 21.6898C54.4509 23.3198 54.6409 25.0198 54.6409 27.0498C54.6409 29.6298 54.1309 32.1698 51.3409 33.2798V33.3598C54.2409 33.7998 55.3309 36.4198 55.3309 40.2698C55.3309 41.4998 55.2609 42.7298 54.9609 43.9198C53.8609 47.6898 51.8809 49.2798 48.2209 49.2798H39.1709ZM45.2509 31.5398C46.5009 31.5398 48.1109 31.6198 48.9109 30.5098C49.3909 29.7598 49.4609 28.7198 49.4609 27.2898C49.4609 25.0698 49.0209 23.9498 46.6709 23.8798H44.3009V31.5398H45.2509ZM45.4009 44.9198C46.4609 44.9198 47.5609 45.0398 48.4809 44.5998C49.8709 43.9198 50.0609 41.9798 50.0609 40.4698C50.0609 37.2498 49.4709 35.8998 46.2909 35.8998H44.3009V44.9098H45.4009V44.9198Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.311 19.6104H61.791V49.2904H57.311V19.6104Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.1311 46.0098H74.0611C73.3411 48.6198 71.9711 49.8298 69.7011 49.8698C65.9911 49.8698 65.1611 47.7998 65.1611 44.1398V27.2998H69.8511V42.3498C69.8511 43.3198 69.8111 44.4198 70.3211 45.2698C70.7211 45.7398 71.1511 45.9698 71.7611 45.9698C73.9611 45.9698 73.9211 43.2798 73.9211 41.5598V27.2998H78.6111V49.2898H74.1411V46.0098H74.1311Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.7811 34.86C81.5711 29.67 84.0111 26.71 88.6211 26.71C95.1311 26.71 95.8111 30.37 95.8111 36.38V39.34H86.4611V42.54C86.5011 45.42 87.5011 45.97 88.9111 45.97C90.6411 45.97 91.1411 44.61 91.0711 41.84H95.7511C95.9311 46.75 94.0611 49.87 89.2811 49.87C83.8811 49.87 81.6911 47.1 81.8011 40.63V34.86H81.7811ZM91.1311 35.45V33.54C91.0911 31.32 90.5511 30.62 88.6811 30.62C86.3411 30.62 86.4511 32.57 86.4511 34.56V35.46H91.1311V35.45Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.941 49.3598C105.291 49.7098 100.231 49.9798 100.231 45.8898V30.9998H97.5107V27.2998H100.191V21.2598H104.711V27.2998H107.941V30.9998H104.711V44.6498C104.811 45.5898 107.391 45.4698 107.941 45.3898V49.3598Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.781 39.07C109.821 31.55 109.891 26.71 117.041 26.71C124.081 26.71 124.121 31.54 124.161 39.07C124.201 46.71 123.171 49.98 117.041 49.87C110.801 49.99 109.741 46.71 109.781 39.07ZM114.521 42.11C114.521 44.8 114.741 45.97 117.041 45.97C119.231 45.97 119.411 44.8 119.411 42.11V34.78C119.411 32.71 119.411 30.61 117.041 30.61C114.521 30.61 114.521 32.71 114.521 34.78V42.11Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.531 39.07C126.571 31.55 126.641 26.71 133.721 26.71C140.691 26.71 140.731 31.54 140.761 39.07C140.801 46.71 139.781 49.98 133.721 49.87C127.551 49.99 126.501 46.71 126.531 39.07ZM131.231 42.11C131.231 44.8 131.451 45.97 133.721 45.97C135.891 45.97 136.071 44.8 136.071 42.11V34.78C136.071 32.71 136.071 30.61 133.721 30.61C131.231 30.61 131.231 32.71 131.231 34.78V42.11Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.441 49.3598C149.791 49.7098 144.731 49.9798 144.731 45.8898V30.9998H142.461V27.2998H144.701V21.2598H149.221V27.2998H152.451V30.9998H149.221V44.6498C149.321 45.5898 151.901 45.4698 152.451 45.3898V49.3598H152.441Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M155.441 49.2804V19.6504H160.221V29.5604H160.291C161.651 26.9904 163.561 26.7104 164.591 26.7104C167.311 26.7104 169.521 28.3904 169.371 32.3204V49.2804H164.591V34.2404C164.591 32.1004 164.371 30.6904 162.571 30.6104C160.771 30.5304 160.141 32.3304 160.211 34.6704V49.2904H155.441V49.2804Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M169.031 19.3604H171.751V19.9803H170.801V23.5903H169.991V19.9803H169.031V19.3604Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M173.041 23.5901H172.231V19.3701H173.521L173.961 21.3301C174.031 21.6501 174.081 21.9801 174.111 22.3001H174.121C174.161 21.8801 174.191 21.6001 174.251 21.3301L174.691 19.3701H175.981V23.5901H175.171V22.2601C175.171 21.4201 175.191 20.5801 175.241 19.7401H175.231L174.361 23.5801H173.871L173.021 19.7401H172.991C173.041 20.5801 173.061 21.4201 173.061 22.2601V23.5901H173.041Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.740956 25.4C1.48096 17.58 7.14096 13.16 15.341 13H16.301C23.311 13.14 26.901 15.48 29.211 19.84C31.511 24.2 33.311 36.6 30.831 45.23C28.361 53.86 23.481 56 15.961 56.17H14.881C8.04096 56.03 4.22096 52.91 2.03096 47.71C0.870956 44.97 0.360957 41.41 0.230957 37.53V33.26C0.300957 30.66 0.490956 28 0.740956 25.4ZM18.2611 34.5401L25.8311 42.1101L14.6711 53.2801V38.3201L8.35105 44.6401L6.08105 42.3701L13.8211 34.6301L6.13105 26.9401L8.40106 24.6701L14.6811 30.9501V16.0801L25.7811 27.0201L18.2611 34.5401ZM21.4311 27.1101L17.5811 23.1801V30.9601L21.4311 27.1101ZM21.4311 41.9801L17.5811 38.0501V45.8301L21.4311 41.9801Z"
        fill="white"
      />
    </svg>
  )
}
