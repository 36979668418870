import React from "react"
import {
  Arduino,
  Kibana,
  Javascript,
  XMPP,
  Java,
  MQTT,
  NodeJS,
  Bluetooth,
  Kuzzle,
} from "../../../images/InternetOfThings/Icons"

import "./TechnologiesList.scss"

export default function TechnologiesList() {
  return (
    <div className="tl-container">
      <div className="tl-wrapper">
        <h3 className="tl-header">Technologies we use:</h3>
        <div className="tl-icon-wrapper">
          <div className="tl-icon-row upper">
            <Arduino />
            <Kibana />
            <Javascript />
            <XMPP />
            <Java />
          </div>
          <div className="tl-icon-row lower">
            <MQTT />
            <NodeJS />
            <Bluetooth />
            <Kuzzle />
          </div>
        </div>
      </div>
    </div>
  )
}
