import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import Banner from "../../components/InternetOfThings/Banner"
import TextBlock from "../../components/InternetOfThings/TextBlock"
import TechnologiesList from "../../components/InternetOfThings/TechnologiesList"
import LatestProjects from "../../components/InternetOfThings/LatestProjects"
import ContactUsExpertise from "../../components/CommonComponents/ContactUsExpertise"
import { mainUrl } from "../../js/config"

export default function InternetOfThings() {
  const sendUrl = typeof window !== "undefined" ? window?.location?.href : ""
  return (
    <div>
      <SEO
        title="IoT Development"
        description="Among the wide range of services, we offer Internet of Things development of any scope and for any business kind. "
        canonical={`${mainUrl}/expertise/internet-of-things/`}
      />
      <Layout showFormPoint={100}>
        <Banner />
        <TextBlock />
        <TechnologiesList />
        <LatestProjects />
        <ContactUsExpertise form={0} url={sendUrl} />
      </Layout>
    </div>
  )
}
