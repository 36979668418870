import React from "react"

export default function Kuzzle() {
  return (
    <svg
      width="141"
      height="70"
      viewBox="0 0 141 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M71.5472 54.0688L69.7639 57.0409H2V20.7812H80.4635"
        stroke="white"
      />
      <path
        d="M24.5958 44.4102H23.025L19.2758 39.3708L18.194 40.3166V44.4102H16.8604V33.8584H18.194V39.0604C18.4904 38.7254 18.7917 38.3905 19.098 38.0555C19.4043 37.7205 19.7105 37.3855 20.0168 37.0506L22.8768 33.8584H24.4328L20.239 38.4545L24.5958 44.4102Z"
        fill="white"
      />
      <path
        d="M43.9568 33.8584V40.686C43.9568 41.4151 43.8087 42.0752 43.5123 42.6663C43.2159 43.2476 42.7615 43.7107 42.1489 44.0555C41.5463 44.3905 40.7757 44.558 39.8372 44.558C38.5233 44.558 37.5205 44.2033 36.829 43.4939C36.1473 42.7747 35.8065 41.8289 35.8065 40.6565V33.8584H37.1402V40.7008C37.1402 41.558 37.3674 42.223 37.8218 42.6959C38.2862 43.1688 38.9827 43.4053 39.9113 43.4053C40.8696 43.4053 41.5611 43.154 41.9859 42.6516C42.4206 42.1392 42.638 41.4841 42.638 40.686V33.8584H43.9568Z"
        fill="white"
      />
      <path
        d="M63.2252 44.4102H55.8898V43.4052L61.4617 35.0407H56.0677V33.8584H63.077V34.8633L57.5051 43.2279H63.2252V44.4102Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.5963 12.459H140.5V51.6907H67.9805L76.8968 24.3474C79.7591 15.6941 81.6521 12.459 87.5963 12.459ZM92.7133 26.7251H85.2192V28.9795H89.5569L85.0592 35.7449V37.4246H92.8466V35.1702H88.2157L92.7133 28.4048V26.7251ZM106.618 26.7251H104.23V37.4246H110.777V35.1702H106.618V26.7251ZM128.852 26.7251H122.344V37.4246H128.852V35.1835H124.733V32.9757H128.612V30.7478H124.733V28.9662H128.852V26.7251Z"
        fill="white"
      />
    </svg>
  )
}
