import React from "react"

export default function Arduino() {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70ZM40.2081 38.812C39.1129 37.7168 38.2301 36.6143 37.6206 35.7832C37.544 35.6788 37.4719 35.5789 37.4045 35.4841C37.4719 35.3894 37.544 35.2895 37.6206 35.185C38.2301 34.354 39.1129 33.2515 40.2081 32.1563C42.4694 29.895 45.2986 28 48.2769 28C52.3931 28 55.7656 31.3737 55.7656 35.4841C55.7656 39.5946 52.3931 42.9683 48.2769 42.9683C45.2986 42.9683 42.4694 41.0733 40.2081 38.812ZM32.6986 29.3349C33.6211 30.2665 34.401 31.1943 35.0197 31.9967C35.6508 31.1933 36.445 30.2626 37.3797 29.3279C39.8605 26.8471 43.6697 24 48.2769 24C54.601 24 59.7656 29.1634 59.7656 35.4841C59.7656 41.8049 54.601 46.9683 48.2769 46.9683C43.6697 46.9683 39.8605 44.1212 37.3797 41.6404C36.4352 40.696 35.6342 39.7555 35 38.9465C34.3657 39.7556 33.5645 40.6961 32.6198 41.6406C30.1381 44.1216 26.3279 46.9683 21.7187 46.9683C15.3985 46.9683 10.2346 41.8043 10.2346 35.4841C10.2346 29.164 15.3985 24 21.7187 24C26.4173 24 30.2265 26.8382 32.6986 29.3349ZM21.7187 28C17.6077 28 14.2346 31.3731 14.2346 35.4841C14.2346 39.5952 17.6077 42.9683 21.7187 42.9683C24.6996 42.9683 27.5301 41.0729 29.7917 38.8118C30.8871 37.7167 31.7701 36.6142 32.3796 35.7832C32.4603 35.6732 32.5359 35.5682 32.6064 35.469C32.5401 35.3736 32.4691 35.2728 32.3936 35.1673C31.8002 34.3393 30.9368 33.2406 29.8562 32.1493C27.633 29.9039 24.8014 28 21.7187 28ZM25.8985 33.9679H19.0642V36.2445H25.8985V33.9679ZM48.6595 33.9679H50.9408V36.2445H48.6595V38.5211H46.383V36.2445H44.1065V33.9679H46.383V31.6914H48.6595V33.9679Z"
        fill="white"
      />
    </svg>
  )
}
