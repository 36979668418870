import React from "react"

import RobotWithCloud from "../../../images/InternetOfThings/Graphics/RobotWithCloud"

import "./Banner.scss"

export default function Banner() {
  return (
    <div className="iot-banner-container">
      <div className="iot-banner-wrapper">
        <h1 className="iot-banner-header">Internet of Things</h1>
        <div className="robot-container">
          <RobotWithCloud />
        </div>
      </div>
    </div>
  )
}
