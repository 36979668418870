import React from "react"

import { Link } from "gatsby"

import "./LatestProjects.scss"

export default function LatestProjects() {
  return (
    <div className="lp-container">
      <h2 className="lp-header">Work Projects</h2>
      <div className="lp-card-container">
        <img
          className="lp-card-image"
          src={require("../../../images/cases/casesCover/Internet of Things.svg")}
          alt="Internet of Things Logo"
        />
        <div className="lp-info-container">
          <div className="lp-name">Internet of Things</div>
          <div className="lp-info">
            The application allows comparing ongoing equipment performance to
            its standard performance to identify anomalies.
          </div>
          <Link to="/cases/iot/" className="lp-button">
            More
          </Link>
        </div>
      </div>
    </div>
  )
}
