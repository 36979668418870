import React from "react"

import { Link } from "gatsby"

import "./TextBlock.scss"

export default function TextBlock() {
  return (
    <div className="tb-container">
      <div className="tb-wrapper">
        <div className="tb-upper">
          <div className="robot-simple" />
          <div className="tb-upper-text">
            <h3 className="tb-upper-text-header">Explore our IoT expertise</h3>
            <p className="tb-upper-text-paragraph">
              OS-System accumulated extensive experience with Internet of Things
              solutions and services. Our embedded software development team
              create IoT systems that boost operational efficiency and cut costs
              across a wide range of industries. We cooperate with companies
              from all over the globe supporting their data-driven optimizations
              and digital transformations.
            </p>
            <p className="tb-upper-text-paragraph">
              Based on our strong expertise, we can analyse and define your
              specific requirements for your particular IoT development project.
              OS-System as your IoT software development partner can provide you
              with a high-quality communication and project management thanks to
              a transparent business model we offer.
            </p>
            <Link className="tb-upper-text-btn" to="/estimate/">
              Get a free quote
            </Link>
          </div>
        </div>
        <div className="tb-lower">
          <div className="tb-lower-text">
            <h3 className="tb-lower-text-header">
              Wide variety of technology experience
            </h3>
            <p className="tb-lower-text-paragraph">
              We deploy embedded solutions for your particular needs in
              interface, power and maintainability based on our wide variety of
              technology experience. Our engineers are highly skilled in the
              latest technologies, tools, methodologies and techniques in the
              field of IoT development. We are always focused on delivering the
              highest level of performance.
            </p>
          </div>
          <div className="computer" />
        </div>
      </div>
    </div>
  )
}
