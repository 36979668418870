import React from "react"

export default function Java() {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.1667 53.7158C26.1667 53.7158 23.6019 55.2368 27.992 55.7515C33.3104 56.3702 36.0286 56.2815 41.8895 55.1503C41.8895 55.1503 43.4304 56.1356 45.5824 56.9889C32.4437 62.7312 15.847 56.6563 26.1667 53.7158Z"
        fill="white"
      />
      <path
        d="M24.561 46.2236C24.561 46.2236 21.6843 48.3951 26.0776 48.8585C31.7589 49.4562 36.2456 49.5051 44.0092 47.9806C44.0092 47.9806 45.083 49.0907 46.7716 49.6978C30.8861 54.4347 13.1926 50.0714 24.561 46.2236Z"
        fill="white"
      />
      <path
        d="M38.0958 33.5122C41.3332 37.3131 37.2452 40.7335 37.2452 40.7335C37.2452 40.7335 45.4655 36.4061 41.6903 30.9872C38.1644 25.9337 35.4605 23.4228 50.0983 14.7656C50.0983 14.7656 27.1218 20.6175 38.0958 33.5122Z"
        fill="white"
      />
      <path
        d="M55.4726 59.2586C55.4726 59.2586 57.3706 60.8533 53.3823 62.0871C45.7985 64.4299 21.8176 65.1374 15.1559 62.1804C12.7611 61.1181 17.2519 59.6437 18.6646 59.3344C20.1378 59.0086 20.9797 59.0693 20.9797 59.0693C18.3165 57.1561 3.76594 62.8259 13.5887 64.4497C40.377 68.8798 62.4211 62.4548 55.4726 59.2586Z"
        fill="white"
      />
      <path
        d="M27.4 38.4584C27.4 38.4584 15.2018 41.4129 23.0803 42.4859C26.4068 42.94 33.0382 42.8373 39.2152 42.3095C44.2634 41.8752 49.3323 40.9519 49.3323 40.9519C49.3323 40.9519 47.5523 41.7293 46.2645 42.6261C33.8774 45.9483 9.94794 44.4027 16.8369 41.0045C22.663 38.1326 27.4 38.4584 27.4 38.4584Z"
        fill="white"
      />
      <path
        d="M49.2814 50.9312C61.8734 44.2585 56.0514 37.8462 51.9876 38.7101C50.9916 38.9215 50.5475 39.1047 50.5475 39.1047C50.5475 39.1047 50.9173 38.514 51.6235 38.2583C59.6628 35.3761 65.8456 46.7591 49.0283 51.2675C49.0283 51.2677 49.2231 51.09 49.2814 50.9312Z"
        fill="white"
      />
      <path
        d="M41.6893 0.786133C41.6893 0.786133 48.6629 7.9 35.0751 18.8391C24.179 27.6142 32.5904 32.6175 35.0705 38.334C28.7103 32.4821 24.0428 27.3306 27.1741 22.5362C31.7702 15.4983 44.503 12.0861 41.6893 0.786133Z"
        fill="white"
      />
      <path
        d="M28.6371 69.0141C40.7239 69.8031 59.2845 68.5763 59.724 62.7441C59.724 62.7441 58.8791 64.9551 49.735 66.7109C39.4187 68.6907 26.695 68.4595 19.1484 67.1907C19.1486 67.1905 20.6933 68.4946 28.6371 69.0141Z"
        fill="white"
      />
    </svg>
  )
}
