import React from "react"

export default function Kibana() {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 62.8221V69.7836H12.2989L41.2593 35.3519C39.2512 34.0573 37.1597 32.8946 34.9984 31.8711L9 62.8221Z"
        fill="#DDDDDD"
      />
      <path
        d="M41.2606 35.3516L12.2861 69.7972H61.742C61.742 55.7628 53.7123 43.2877 41.2606 35.3516Z"
        fill="#EEEEEE"
      />
      <path d="M9 0L9 62.9274L61.8302 0H9Z" fill="#CCCCCC" />
      <path
        d="M9.01407 62.9051L35.0525 31.8975C26.8907 28.1165 18.0082 26.1582 9 26.1989L9.01407 62.9051Z"
        fill="white"
      />
    </svg>
  )
}
